<!-- analysisReport -->
<template>
  <div class="analysisReport">
    <div
      class="download-btn"
      @click="printOut(reportTitle)"
      :class="isDisabled ? 'download-btn-disabled' : ''"
    >
      <img v-if="isDisabled" :src="downloadReportDisabledImg" alt="" />
      <img v-else :src="downloadReportImg" alt="" />
      下载报告
    </div>
    <div class="analysisReport-content" id="analysisReportContent">
      <div class="page page1">
        <div class="header">
          <div class="title">
            {{ reportTitle || '' }}
          </div>
          <div class="icons">
            <div class="line"></div>
            <img class="small" :src="smallStar" alt="" />
            <img class="big" :src="bigStar" alt="" />
            <img class="small" :src="smallStar" alt="" />
            <div class="line"></div>
          </div>
          <div class="dates">
            <div v-if="taskId && pushTaskInfo">
              传播日期：{{ pushTaskInfo.spreadDate }}
            </div>
            <div>数据统计日期：{{ todayDate }}</div>
          </div>
        </div>
        <div class="main-content-box">
          <div class="num-count-box">
            <template v-for="(item, index) in numCountList">
              <div
                class="num-count-item"
                v-if="item.isEmptyVisible ? item.count : true"
                :key="index"
              >
                <div class="num-box">
                  <div :class="['num' + index, 'num']">
                    {{ item.count || '--' }}
                  </div>
                  <div class="text">{{ item.text }}</div>
                </div>
                <!-- <div class="rate-text">
                <img
                  v-if="item.rateSymbol == 1"
                  src="@assets/img/doc-asc.png"
                  alt=""
                />
                <img
                  v-if="item.rateSymbol == -1"
                  src="@assets/img/doc-desc.png"
                  alt=""
                />
                <span
                  class="rate"
                  :class="
                    item.rateSymbol == 1
                      ? 'asc'
                      : item.rateSymbol == -1
                      ? 'desc'
                      : ''
                  "
                  >{{ item.rateNum || '--' }}</span
                >
                <span class="text"> {{ pushTaskInfo.rateText }}</span>
              </div> -->
              </div>
            </template>
          </div>
          <!-- 邮件传播/微信传播 -->
          <div class="part part1 flex-content">
            <div class="emailPush" v-if="hideEmail">
              <div class="first-title">邮件传播</div>
              <div class="num-list">
                <div
                  class="list-item"
                  v-for="(item, index) in emailInfoList"
                  :key="index"
                >
                  <div class="num-box">
                    <div class="num">{{ item.num }}</div>
                    <div class="text">{{ item.text }}</div>
                  </div>
                  <!-- <div class="rate-text">
                    <img
                      v-if="item.rateSymbol == 1"
                      src="@assets/img/doc-asc.png"
                      alt=""
                    />
                    <img
                      v-if="item.rateSymbol == -1"
                      src="@assets/img/doc-desc.png"
                      alt=""
                    />
                    <span
                      class="rate"
                      :class="
                        item.rateSymbol == 1
                          ? 'asc'
                          : item.rateSymbol == -1
                          ? 'desc'
                          : ''
                      "
                      >{{ item.rate || '--' }}</span
                    >
                  </div> -->
                </div>
              </div>
            </div>
            <div class="weChatPush" v-if="hideWx">
              <div class="first-title">微信传播</div>
              <div class="num-list">
                <div
                  class="list-item"
                  v-for="(item, index) in wxInfoList"
                  :key="index"
                >
                  <div class="num-box">
                    <div class="num">{{ item.num }}</div>
                    <div class="text">{{ item.text }}</div>
                  </div>
                  <!-- <div class="rate-text">
                    <img
                      v-if="item.rateSymbol == 1"
                      src="@assets/img/doc-asc.png"
                      alt=""
                    />
                    <img
                      v-if="item.rateSymbol == -1"
                      src="@assets/img/doc-desc.png"
                      alt=""
                    />
                    <span
                      class="rate"
                      :class="
                        item.rateSymbol == 1
                          ? 'asc'
                          : item.rateSymbol == -1
                          ? 'desc'
                          : ''
                      "
                      >{{ item.rate || '--' }}</span
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 网站引流分析 -->
          <div class="part part2">
            <h3>网站引流分析</h3>
            <div class="flex-content">
              <div
                class="left-content"
                :style="showCBYLL ? '' : 'display: none'"
              >
                <div class="first-title">近五期传播引流量</div>
                <div
                  class="mt-20"
                  id="chartCBYLL"
                  :style="{
                    width: '497px',
                    height: '420px'
                  }"
                />
              </div>
              <div
                class="left-content"
                :style="showCBYLL ? 'display: none' : ''"
              >
                <div class="first-title">近五期传播前后官网访问量</div>
                <div
                  class="mt-20"
                  id="chartGWFWL"
                  :style="{
                    width: '497px',
                    height: '420px'
                  }"
                />
              </div>
              <div class="right-content">
                <div class="first-title">本期传播前后文献阅读量对比及排名</div>
                <div
                  class="mt-20"
                  id="chartWZYDQK"
                  :style="{
                    width: '497px',
                    height: '420px'
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page page2">
        <div class="main-content-box">
          <!-- 热门内容 -->
          <!-- 本期文献阅读量排行 -->
          <div class="part part3 mb-20">
            <h3>热门内容</h3>
            <div class="first-title mb-20">本期文献阅读量排行</div>
            <commonTable
              :table-data="WXYDLTableData"
              :column-data="WXYDLColumnData"
              serial-text="排名"
              no-pagination
              align-text="left"
            />
          </div>
          <!-- 本年度文献关键词分布 -->
          <div class="part part4">
            <div class="first-title mb-20">本年度文献关键词分布</div>
            <div class="flex-content">
              <div
                id="chartGJC"
                :style="{
                  width: '55%',
                  height: '420px'
                }"
              />
              <commonTable
                class="GJCTable"
                no-pagination
                align-text="left"
                serial-text="排名"
                :table-data="GJCTableData"
                :column-data="GJCColumnData"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="page page3">
        <div class="main-content-box">
          <!-- 用户分析 -->
          <div class="part part5">
            <h3>用户分析</h3>
            <div class="flex-content mt-20">
              <div class="left-content" v-if="hideEmail">
                <div class="first-title mb-20">阅读邮件用户来源机构</div>
                <commonTable
                  :table-data="YDYJYHLYJGTableData"
                  :column-data="YDYJYHLYJGColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
              <div class="right-content" v-if="hideEmail">
                <div class="first-title mb-20">阅读邮件用户来源期刊</div>
                <commonTable
                  :table-data="YDYJYHLYQKTableData"
                  :column-data="YDYJYHLYQKColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
            </div>
            <div class="flex-content mt-20">
              <div class="left-content" v-if="hideEmail">
                <div class="first-title mb-20">阅读邮件活跃用户排行</div>
                <commonTable
                  :table-data="YDYJHYYHTableData"
                  :column-data="YDYJHYYHColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
              <div class="right-content" v-if="hideWx">
                <div class="first-title mb-20">阅读微网刊活跃用户排行</div>
                <commonTable
                  :table-data="YDWWKHYYHTableData"
                  :column-data="YDWWKHYYHColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
            </div>
            <div class="flex-content mt-20">
              <div class="left-content" v-if="hideEmail">
                <div class="first-title mb-20">阅读邮件用户地域分布-国内</div>
                <commonTable
                  :table-data="chinaTableData"
                  :column-data="chinaColumnData"
                  no-pagination
                  align-text="left"
                />
                <p
                  class="mt-20"
                  style="font-size: 14px; text-align: center; color: #999"
                >
                  国内阅读邮件用户分布地区共{{ chinaLength }}个。
                </p>
              </div>
              <div class="right-content" v-if="hideEmail">
                <div class="first-title mb-20">阅读邮件用户地域分布-海外</div>
                <commonTable
                  :table-data="worldTableData"
                  :column-data="worldColumnData"
                  no-pagination
                  align-text="left"
                />
                <p
                  class="mt-20"
                  style="font-size: 14px; text-align: center; color: #999"
                >
                  海外阅读邮件用户分布地区共{{ worldLength }}个。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page page4">
        <div class="main-content-box">
          <!-- 传播效果趋势分析 -->
          <div class="part part6" v-if="hideEmail">
            <h3>传播效果趋势分析</h3>
            <div class="flex-content">
              <div class="left-content">
                <div class="first-title mb-20">近五期阅读邮件用户量</div>
                <div
                  id="chartJWQYDYJYH"
                  :style="{
                    width: '100%',
                    height: '340px'
                  }"
                />
              </div>
              <div class="right-content">
                <div class="first-title mb-20">近五期阅读文献用户量</div>
                <div
                  id="chartJWQYDWXYH"
                  :style="{
                    width: '100%',
                    height: '340px'
                  }"
                />
              </div>
            </div>
          </div>
          <!-- 引证追踪 -->
          <div class="part part7">
            <h3>引证追踪</h3>
            <div class="first-title mb-20">近三年文献被引统计Top10</div>
            <commonTable
              :table-data="JSNWXBYTableData"
              :column-data="JSNWXBYColumnData"
              serial-text="排名"
              no-pagination
              align-text="left"
            />
            <p
              v-if="JSNWXBYTableData.length > 0"
              class="mt-20"
              style="text-align: center; color: #ccc; font-size: 12px"
            >
              截至{{ todayDate }}，{{ YZZZData.startYear }}年第{{
                YZZZData.startIssue
              }}期至{{ YZZZData.endYear }}年第{{
                YZZZData.endIssue
              }}期文献中，累计{{ YZZZData.articleCount }}篇文章被{{
                YZZZData.citationCount
              }}篇文章所引用，以上为被引次数排名前10的文献
            </p>
            <div class="flex-content mt-20">
              <div class="left-content">
                <div class="first-title mb-20">近三年发文机构被引统计Top10</div>
                <commonTable
                  :table-data="JSNFWJGBYTableData"
                  :column-data="JSNFWJGBYColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
              <div class="right-content">
                <div class="first-title mb-20">近三年引证期刊引证统计Top10</div>
                <commonTable
                  :table-data="JSNYZQKYZTableData"
                  :column-data="JSNYZQKYZColumnData"
                  serial-text="排名"
                  no-pagination
                  align-text="left"
                />
              </div>
            </div>
          </div>
        </div>
        <p class="tip">北京北大方正电子有限公司提供技术支持</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import echarts from 'echarts'
import commonTable from '@comp/commonTable'
import 'echarts-wordcloud'
import { getPdf } from '@assets/js/htmlToPdf.js'
const WXApi = ProjectConfig.weChatPush
const CEApi = ProjectConfig.communicationEffectStatistics
const EMAILApi = ProjectConfig.emailPush
export default {
  name: 'AnalysisReport',
  data() {
    return {
      loading: true,
      downloadReportImg: require('@assets/img/download-report.png'),
      downloadReportDisabledImg: require('@assets/img/download-report-disabled.png'),
      smallStar: require('@assets/img/star-small.png'),
      bigStar: require('@assets/img/star-big.png'),
      WXBasicInfo: {},
      pushTaskInfo: {},
      emailInfoList: [],
      wxInfoList: [],
      isDisabled: true,
      numCountList: [],
      WXYDLTableData: [],
      WXYDLColumnData: [
        {
          title: '文献标题',
          prop: 'topic',
          special: true,
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '文献作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量',
          prop: 'clickCount',
          width: '60px',
          notSortable: true,
          align: 'center'
        }
      ],
      GJCTableData: [],
      GJCColumnData: [
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '频次',
          prop: 'count',
          notSortable: true,
          width: '50px',
          align: 'center'
        }
      ],
      showCBYLL: true,
      YDYJYHLYJGTableData: [],
      YDYJYHLYJGColumnData: [
        {
          title: '机构',
          prop: 'orgName',
          notSortable: true
        },
        {
          title: '用户数',
          prop: 'count',
          notSortable: true,
          width: '60px',
          align: 'center'
        }
      ],
      YDYJYHLYQKTableData: [],
      YDYJYHLYQKColumnData: [
        {
          title: '期刊',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '用户数',
          prop: 'count',
          notSortable: true,
          width: '60px',
          align: 'center'
        }
      ],
      YDYJHYYHTableData: [],
      YDYJHYYHColumnData: [
        {
          title: '用户姓名',
          prop: 'name',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'orgNames',
          notSortable: true
        },
        {
          title: '阅读文章人次',
          prop: 'sumReadCount',
          notSortable: true,
          width: '120px',
          align: 'center'
        }
      ],
      YDWWKHYYHTableData: [],
      YDWWKHYYHColumnData: [
        {
          title: '微信昵称',
          prop: 'nickName',
          notSortable: true
        },
        {
          title: '阅读文章人次',
          prop: 'articleCount',
          notSortable: true,
          width: '120px',
          align: 'center'
        }
      ],
      YZZZData: {},
      JSNWXBYTableData: [],
      JSNWXBYColumnData: [
        {
          title: '刊期',
          prop: 'issueText',
          notSortable: true,
          width: '120px'
        },
        {
          title: '被引文献',
          prop: 'root_title',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      JSNFWJGBYTableData: [],
      JSNFWJGBYColumnData: [
        {
          title: '机构',
          prop: 'INSTITUTION',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      JSNYZQKYZTableData: [],
      JSNYZQKYZColumnData: [
        {
          title: '期刊',
          prop: 'CITATIONS_MAG',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'frequency',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      chinaTableData: [],
      chinaColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'percent',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      worldTableData: [],
      worldColumnData: [
        {
          title: '地区',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true,
          width: '80px',
          align: 'center'
        },
        {
          title: '占比',
          prop: 'percent',
          notSortable: true,
          width: '80px',
          align: 'center'
        }
      ],
      chinaLength: 0,
      worldLength: 0,
      taskId: '',
      networkId: '',
      type: '',
      year: '',
      issue: '',
      currentItem: {}
    }
  },
  props: {
    // taskId: {
    //   type: [String, Number],
    //   default: ''
    // },
    // networkId: {
    //   type: [String, Number],
    //   default: ''
    // },
    // type: {
    //   type: [String, Number],
    //   default: ''
    // },
    // year: {
    //   type: [String, Number],
    //   default: ''
    // },
    // issue: {
    //   type: [String, Number],
    //   default: ''
    // }
  },
  watch: {
    magId() {
      this.getData(
        this.taskId,
        this.networkId,
        this.type,
        this.year,
        this.issue
      )
    }
  },
  components: { commonTable },
  computed: {
    ...mapState('incrementService', ['magId']),
    todayDate() {
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      return year + '年' + month + '月' + day + '日'
    },
    reportTitle() {
      let yearText = this.pushTaskInfo.year ? this.pushTaskInfo.year + '年' : ''
      let issueText = this.pushTaskInfo.magIssue
        ? '第' + this.pushTaskInfo.magIssue + '期'
        : ''
      let text = ''
      if (this.taskId && this.type != 2) {
        text =
          (this.pushTaskInfo.magName
            ? '《' + this.pushTaskInfo.magName + '》'
            : '') +
          yearText +
          issueText +
          '文献主动传播数据统计报告'
      } else {
        text = this.currentItem.historyTopic || this.WXBasicInfo.sysTopic
      }
      return text
    },
    // 是否隐藏邮件传播
    hideEmail() {
      // 如果每一项都没有值则隐藏
      return !this.emailInfoList.every((item) => !item.num)
    },
    // 是否隐藏微信传播
    hideWx() {
      // 如果每一项都没有值则隐藏
      return !this.wxInfoList.every((item) => !item.num)
    }
  },
  mounted() {},
  methods: {
    getData(taskId, networkId, type, year, issue) {
      this.taskId = taskId
      this.networkId = networkId
      this.type = type
      this.year = year
      this.issue = issue
      this.WXBasicInfo = {}
      this.pushTaskInfo = {}
      this.emailInfoList = []
      this.wxInfoList = []
      this.numCountList = []
      this.WXYDLTableData = []
      this.GJCTableData = []
      this.YDYJYHLYJGTableData = []
      this.YDYJYHLYQKTableData = []
      this.YDYJHYYHTableData = []
      this.YDWWKHYYHTableData = []
      this.YZZZData = {}
      this.JSNWXBYTableData = []
      this.JSNFWJGBYTableData = []
      this.JSNYZQKYZTableData = []
      this.chinaTableData = []
      this.worldTableData = []
      this.chinaLength = 0
      this.worldLength = 0
      /* this.loading = true */
      this.$emit('loadingChange', true)
      Promise.allSettled([
        this.getTaskList(),
        // 微网刊基本信息
        this.getNetworkJournalInfo(),
        // 本期文献阅读量排行
        this.getArticleListData(),
        // 本期传播前后文献阅读量对比及排名
        this.getChartData(
          'WZYDQK',
          {
            magId: this.magId,
            type: this.type,
            year: this.year,
            issue: this.issue
          },
          CEApi.GetVisitCount.url
        ),
        // this.getKeywords(),
        // 统计报告总数统计
        this.getPushTaskReportInfo(),
        // 邮件传播、微信传播
        this.getPushTaskReportSpread(),
        // 近五期传播前后官网访问量
        this.getChartData(
          'GWFWL',
          {
            taskId: this.taskId,
            networkId: this.networkId
          },
          CEApi.GetWebsiteVisit.url
        ),
        // 阅读邮件用户来源机构
        this.getYDYJYHLYJGTableData(),
        // 阅读邮件用户来源期刊
        this.getYDYJYHLYQKTableData(),
        // 阅读邮件活跃用户排行
        this.getYDYJHYYHTableData(),
        // 阅读微网刊活跃用户排行
        this.getYDWWKHYYHTableData(),
        //传播路径分析
        this.getDiffusePath(1),
        this.getDiffusePath(2),
        this.getKeywords(),
        // 引证追踪
        this.getLatestCitationCount(),
        // 传播效果趋势分析
        this.getChartData(
          'CBXGQSFX',
          {
            taskId: this.taskId
          },
          CEApi.GetEmailOnlyCount.url
        )
      ]).then(() => {
        /* this.loading = false */
        this.$emit('loadingChange', false)
        this.isDisabled = false
      })
    },
    // 列表获取
    getTaskList(pageParams = {}) {
      return new Promise((resolve) => {
        const { page = 1, size = 10 } = pageParams
        const params = {
          page: page - 1,
          size,
          magId: this.magId
        }
        let url = CEApi.GetTaskList.url
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data && data.content && data.content.length > 0) {
              data.content.map((item) => {
                if (item.historyId == this.taskId) {
                  this.currentItem = item
                }
              })
            }
            resolve()
          }
        })
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      return new Promise((resolve) => {
        if (type === 'CBXGQSFX' && !this.taskId) {
          this.chartJWQYDYJYH([])
          this.chartJWQYDWXYH([])
          resolve()
        } else {
          Get(url, { params }).then((res) => {
            if (res && res.data && res.data.status === 0) {
              const { data } = res.data
              let _this = this
              this.$nextTick(() => {
                if (type === 'GWFWL') {
                  if (
                    data &&
                    data.websiteVisitCount &&
                    data.websiteVisitCount.length > 0
                  ) {
                    data.websiteVisitCount.map((item) => {
                      if (
                        item &&
                        (item.earliestSumVisitCount || item.latestSumVisitCount)
                      ) {
                        _this.showCBYLL = false
                      }
                    })
                  } else if (!data.websiteVisitCount) {
                    _this.showCBYLL = true
                  }
                  if (_this.showCBYLL) {
                    _this.chartCBYLL(data.sumLogVisitCount)
                  } else {
                    _this.chartGWFWL(data.websiteVisitCount)
                  }
                } else if (type === 'WZYDQK') {
                  _this.chartWZYDQK(data)
                } else if (type === 'CBXGQSFX') {
                  _this.chartJWQYDYJYH(data)
                  _this.chartJWQYDWXYH(data)
                }
                resolve()
              })
            }
          })
        }
      })
    },
    getNetworkJournalInfo() {
      if (!this.networkId) return
      const params = {
        id: this.networkId
      }
      let url = WXApi.GetNetworkJournalInfo.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.WXBasicInfo = data
            resolve()
          }
        })
      })
    },
    getPushTaskReportInfo() {
      this.numCountList = []
      const params = {
        taskId: this.taskId,
        networkId: this.networkId,
        type: this.type
      }
      let url = CEApi.GetPushTaskReportInfo.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            data.rateText =
              !data.lastHistoryMagIssue || data.lastHistoryMagIssue === '01'
                ? ''
                : '（相比第' + data.lastHistoryMagIssue + '期）'
            this.pushTaskInfo = data
            this.numCountList = [
              {
                text: '送达用户',
                count: data.pushSuccessCount,
                rateNum: data.pushAuthorPercentNumber,
                rateSymbol: data.pushAuthorPercentNumberSymbol,
                isEmptyVisible: true // 字段为空时是否显示判断（此字段为true如果数量为空则隐藏对应的dom）
              },
              {
                text: '机构覆盖',
                count: data.countOrg,
                rateNum: data.countOrgPercentNumber,
                rateSymbol: data.countOrgPercentSymbol,
                isEmptyVisible: true // 字段为空时是否显示判断（此字段为true如果数量为空则隐藏对应的dom）
              },
              {
                text: '期刊覆盖',
                count: data.countMag,
                rateNum: data.countMagPercentNumber,
                rateSymbol: data.countMagPercentSymbol,
                isEmptyVisible: true // 字段为空时是否显示判断（此字段为true如果数量为空则隐藏对应的dom）
              },
              {
                text: '白名单',
                count: data.countWhiteListAuthor,
                rateNum: data.countWhiteListAuthorPercentNumber,
                rateSymbol: data.countWhiteListAuthorPercentSymbol,
                isEmptyVisible: true // 字段为空时是否显示判断（此字段为true如果数量为空则隐藏对应的dom）
              }
            ]
            resolve()
          }
        })
      })
    },
    getPushTaskReportSpread() {
      this.emailInfoList = []
      this.wxInfoList = []
      const params = {
        taskId: this.taskId,
        networkId: this.networkId
      }
      let url = CEApi.GetPushTaskReportSpread.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data) {
              this.emailInfoList = [
                {
                  text: '邮件阅读次数',
                  num: data.emailOPenCount,
                  rate: data.emailOpenCountPercent,
                  rateSymbol: data.emailOpenCountPercentSymbol
                },
                {
                  text: '邮件阅读人数',
                  num: data.onlyEmailOpenCount,
                  rate: data.onlyEmailOpenCountPercent,
                  rateSymbol: data.onlyEmailOpenCountPercentSymbol
                }
                // {
                //   text: '文章阅读人次',
                //   num: data.readCount,
                //   rate: data.readCountPercent,
                //   rateSymbol: data.readCountPercentSymbol
                // },
                // {
                //   text: '文章阅读人数',
                //   num: data.onlyReadCount,
                //   rate: data.onlyReadCountPercent,
                //   rateSymbol: data.onlyReadCountPercentSymbol
                // }
              ]
              this.wxInfoList = [
                {
                  text: '微网刊访问次数',
                  num: data.netWorkCount,
                  rate: data.netWorkCountPercent,
                  rateSymbol: data.netWorkCountPercentSymbol
                }
                // {
                //   text: '文章阅读人次',
                //   num: data.articleCount,
                //   rate: data.articleCountPercent,
                //   rateSymbol: data.articleCountPercentSymbol
                // },
                // {
                //   text: '文章阅读人数 (已授权)',
                //   num: data.userCount,
                //   rate: data.userCountPercent,
                //   rateSymbol: data.userCountPercentSymbol
                // }
              ]
            }
            resolve()
          }
        })
      })
    },
    chartGWFWL(data) {
      if (!document.getElementById('chartGWFWL')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartGWFWL'))
      const titleArr = []
      const earliestSumVisitCountArr = []
      const latestSumVisitCountArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          if (item) {
            item.title = item.issue ? '第' + item.issue + '期' : ''
            titleArr.push(item.title)
            earliestSumVisitCountArr.push(item.earliestSumVisitCount || 0)
            latestSumVisitCountArr.push(item.latestSumVisitCount || 0)
          }
        })
      }
      myChart.setOption(
        {
          color: ['#91CC75', '#5470C6'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            top: '3%',
            data: ['传播前', '传播后']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          grid: {
            left: '15%',
            bottom: '15%',
            top: '12%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '传播前',
              type: 'bar',
              barMaxWidth: 30,
              data: earliestSumVisitCountArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '传播后',
              type: 'bar',
              barMaxWidth: 30,
              data: latestSumVisitCountArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    chartCBYLL(data) {
      if (!document.getElementById('chartCBYLL')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartCBYLL'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          item.title = item.issue ? '第' + item.issue + '期' : ''
          titleArr.push(item.title)
          countArr.push(item.sumCount || 0)
        })
      }
      myChart.setOption(
        {
          color: ['#5470C6'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          grid: {
            left: '15%',
            bottom: '15%',
            top: '12%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              data: countArr,
              barWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    getKeywords() {
      const params = {
        magId: this.magId,
        year: this.year
      }
      let url = CEApi.GetKeywords.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.GJCTableData = data
            this.chartGJC(data)
            resolve()
          }
        })
      })
    },
    chartGJC(data) {
      if (!document.getElementById('chartGJC')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartGJC'))
      let list = []
      if (data && data.length > 0) {
        list = data
        list.map((item) => {
          item.name = item.keywords
          item.value = item.count
        })
      }
      myChart.setOption(
        {
          //数据可以点击
          tooltip: {},
          series: [
            {
              // maskImage: maskResource,
              //词的类型
              type: 'wordCloud',
              //设置字符大小范围
              sizeRange: [16, 70],
              size: ['100%', '100%'],
              rotationRange: [-90, 90],
              width: '95%',
              height: '95%',
              gridSize: 4,
              textStyle: {
                normal: {
                  //生成随机的字体颜色
                  color: () => {
                    return (
                      'rgb(' +
                      [
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160),
                        Math.round(Math.random() * 160)
                      ].join(',') +
                      ')'
                    )
                  }
                }
              },
              //不要忘记调用数据
              data: list
            }
          ]
        },
        true
      )
    },
    // 本期文献阅读量排行
    getArticleListData(pageParams = {}) {
      this.WXYDLTableData = []
      const { page = 1, size = 15 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId,
        type: this.type,
        year: this.year,
        issue: this.issue
      }
      let url = CEApi.ArticleClickRankList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            const { content = [] } = data
            this.WXYDLTableData = content
            if (this.WXYDLTableData.length > 0) {
              this.WXYDLTableData.map((item) => {
                item.keywords = item.keywords.replace(/<[^>]+>/g, '')
                item.topic = item.topic.replace(/<[^>]+>/g, '')
              })
            }
          }
        }
      })
    },
    // 推送前后单篇文章阅读情况echarts图
    chartWZYDQK(data) {
      if (!document.getElementById('chartWZYDQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartWZYDQK'))
      const titleArr = []
      const startVisitCountArr = []
      const endVisitCountArr = []
      if (data && data.pushArticleVisitMapList.length > 0) {
        data.pushArticleVisitMapList.map((item) => {
          if (item.startVisitCount && item.networkStartVisitCount) {
            item.resultStartCount =
              item.startVisitCount - item.networkStartVisitCount > 0
                ? item.networkStartVisitCount
                : item.startVisitCount
          } else {
            item.resultStartCount = item.startVisitCount
              ? item.startVisitCount
              : item.networkStartVisitCount
              ? item.networkStartVisitCount
              : 0
          }
        })
        let list = []
        if (data.pushArticleVisitMapList.length > 10) {
          list = data.pushArticleVisitMapList.slice(0, 10)
        } else {
          list = data.pushArticleVisitMapList
        }
        list.map((item) => {
          item.title = item.title.replace(/<[^>]+>/g, '')
          titleArr.push(item.title)
          startVisitCountArr.push(item.resultStartCount || 0)
          endVisitCountArr.push(item.endVisitCount || 0)
        })
      }
      myChart.setOption(
        {
          color: ['#91CC75', '#5470C6'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            top: '3%',
            data: ['传播前', '传播后']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              barMaxWidth: 25,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: (value) => {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '15%',
            bottom: '15%',
            top: '12%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '传播前',
              type: 'bar',
              data: startVisitCountArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '传播后',
              type: 'bar',
              data: endVisitCountArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    getYDYJYHLYJGTableData() {
      const params = {
        taskId: this.taskId,
        page: 0,
        size: 10
      }
      let url = EMAILApi.GetOpenEmailOrg.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.YDYJYHLYJGTableData = data.openEmailOrgList
            resolve()
          }
        })
      })
    },
    getYDYJYHLYQKTableData() {
      const params = {
        taskId: this.taskId,
        page: 0,
        size: 10
      }
      let url = EMAILApi.GetOpenEmailMag.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.YDYJYHLYQKTableData = data.openEmailMagList
            resolve()
          }
        })
      })
    },
    getYDYJHYYHTableData() {
      const params = {
        taskId: this.taskId,
        page: 0,
        size: 10
      }
      let url = EMAILApi.GetAuthorSumList.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.YDYJHYYHTableData = data.content
            resolve()
          }
        })
      })
    },
    getYDWWKHYYHTableData() {
      const params = {
        networkId: this.networkId,
        page: 0,
        size: 10
      }
      let url = WXApi.GetUserAccess.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.YDWWKHYYHTableData = data.content
            resolve()
          }
        })
      })
    },
    getDiffusePath(type) {
      const params = {
        year: this.year,
        magId: this.magId,
        page: 0,
        size: 10,
        type: type,
        taskId: this.taskId
      }
      let url = CEApi.GetDiffusePath.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data) {
              if (type == 1) {
                this.chinaLength = data.length
                this.chinaTableData =
                  data.length > 10 ? data.splice(0, 10) : data
                this.chinaTableData.map((it) => {
                  if (
                    it &&
                    it.territory &&
                    it.territory.indexOf('中国-') != -1
                  ) {
                    it.territory = it.territory.split('中国-')[1]
                  }
                })
              }
              if (type == 2) {
                this.worldLength = data.length
                this.worldTableData =
                  data.length > 10 ? data.splice(0, 10) : data
              }
            }

            resolve()
          }
        })
      })
    },
    getLatestCitationCount() {
      const params = {
        taskId: this.taskId,
        networkId: this.networkId
      }
      let url = CEApi.GetLatestCitationCount.url
      return new Promise((resolve) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.YZZZData = data
            this.JSNWXBYTableData = data.articleCitationCount
            if (this.JSNWXBYTableData.length > 0) {
              this.JSNWXBYTableData.map((item) => {
                item.issueText =
                  (item.year ? item.year + '年' : '') +
                  (item.issue ? '第' + item.issue + '期' : '')
              })
            }
            this.JSNFWJGBYTableData = data.countOrg
            this.JSNYZQKYZTableData = data.countMag
            /* this.loading = false */
            this.$emit('loadingChange', false)
            this.isDisabled = false
            resolve()
          }
        })
      })
    },
    chartJWQYDYJYH(data) {
      console.log(data, 'data')
      if (!document.getElementById('chartJWQYDYJYH')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartJWQYDYJYH'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          item.title = item.issue ? '第' + item.issue + '期' : ''
          titleArr.push(item.title)
          countArr.push(item.onlyEmailOPenCount || 0)
        })
      }
      myChart.setOption(
        {
          color: ['#5470C6', '#91CC75'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          grid: {
            left: '12%',
            bottom: '8%',
            top: '12%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              data: countArr,
              barWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    chartJWQYDWXYH(data) {
      if (!document.getElementById('chartJWQYDWXYH')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartJWQYDWXYH'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          item.title = item.issue ? '第' + item.issue + '期' : ''
          titleArr.push(item.title)
          countArr.push(item.onlyReadCount || 0)
        })
      }
      myChart.setOption(
        {
          color: ['#5470C6', '#91CC75'],
          backgroundColor: '#F5F7FD',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          grid: {
            left: '12%',
            bottom: '8%',
            top: '12%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '',
              type: 'bar',
              data: countArr,
              barWidth: 30,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    printOut(name) {
      if (this.isDisabled) {
        return
      }
      this.$nextTick(() => {
        let pdfWidth =
          document.getElementsByClassName('analysisReport-content')[0]
            .offsetWidth / 2
        let pdfHeight =
          document.getElementsByClassName('analysisReport-content')[0]
            .offsetHeight / 2
        getPdf('analysisReportContent', name, pdfWidth, pdfHeight, 'a4')
      })
    }
  }
}
</script>
<style lang="scss">
.analysisReport {
  .el-table--border td,
  .el-table--border th {
    border-right: 0 !important;
  }
  .el-table--border {
    border-left: none;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #fafafa;
  }
  .el-table__header-wrapper {
    .el-table__header {
      tr th {
        background: #f1f2f4;
      }
    }
  }
}
.GJCTable {
  .serial1,
  .serial2,
  .serial3 {
    width: 20px;
    height: 20px;
    background: #a6aabb;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 20px;
  }
}
.analysisReport {
  .el-loading-spinner {
    top: 300px !important;
  }
}
</style>
<style lang="scss" scoped>
.analysisReport {
  .download-btn {
    display: block;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #4054af;
    display: flex;
    line-height: 27px;
    justify-content: flex-end;
    margin: 20px;
    position: absolute;
    right: 0;
    top: 0;
    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
  .download-btn-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }
  .analysisReport-content {
    width: 1135px;
    margin: auto;
    background-color: #f5f7fd;
    border: 1px solid #f5f7fd;
    .header {
      padding-top: 80px;
      padding-bottom: 20px;
      .title {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #4054af;
        line-height: 36px;
        text-align: center;
      }
      .icons {
        display: flex;
        align-items: center;
        margin-top: 33px;
        .line {
          width: 46%;
          height: 2px;
          border-top: 1px solid #666666;
          border-bottom: 1px solid #666666;
        }
        .small {
          margin: 0 15px;
        }
      }
      .dates {
        text-align: right;
        margin-top: 55px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        margin-right: 10px;
      }
    }
    .main-content-box {
      background-color: #fff;
      padding: 40px;
      .asc {
        color: #ec5463 !important;
      }
      .desc {
        color: #2db897 !important;
      }
      .num-count-box {
        display: flex;
        justify-content: space-between;
        gap: 0 20px;
        .num-count-item {
          flex: 1;
          height: 140px;
          background: #f5f7fd;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          // .num-box {
          //   width: 100%;
          //   height: 140px;
          //   display: table-cell;
          //   vertical-align: middle;
          // }
          .num {
            font-size: 32px;
            font-family: Arial;
            font-weight: 400;
          }
          .num0 {
            color: #ec5463;
          }
          .num1 {
            color: #0ba9e1;
          }
          .num2 {
            color: #ec5463;
          }
          .num3 {
            color: #eea806;
          }
          .text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-top: 10px;
          }
          .rate-text {
            border-top: 1px solid rgba(166, 170, 187, 0.25);
            height: 33px;
            line-height: 33px;
            .rate {
              color: #999999;
            }
            img {
              margin: 0 4px 3px 0;
            }
          }
        }
      }
      .flex-content {
        display: flex;
        justify-content: space-between;
        gap: 0 20px;
        .left-content,
        .right-content {
          // width: 49%;
          flex: 1;
        }
      }
      .first-title {
        color: #333333;
        font-size: 18px;
        text-indent: 10px;
        line-height: 18px;
        font-weight: normal;
        &::before {
          width: 4px;
          height: 18px;
          color: #4054af;
        }
      }
      .part {
        h3 {
          font-size: 22px;
        }
      }
      .part1 {
        margin-top: 40px;
        justify-content: space-between;
        gap: 0 20px;
        .emailPush {
          .list-item,
          .num-box {
            // width: 115px;
            flex: 1;
          }
        }
        .weChatPush {
          .list-item,
          .num-box {
            // width: 155px;
            flex: 1;
          }
        }
        .emailPush,
        .weChatPush {
          flex: 1;
          // width: 49%;
          .num-list {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            gap: 0 20px;
            .list-item {
              height: 107px;
              background: #fafafa;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              .num-box {
                display: table-cell;
                vertical-align: middle;
                // height: 76px;
                .num {
                  color: #666666;
                  font-size: 26px;
                  font-family: Arial;
                }
                .text {
                  color: #999999;
                  margin-top: 4px;
                  font-size: 14px;
                }
              }
              .rate-text {
                border-top: 1px solid rgba(166, 170, 187, 0.25);
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                img {
                  margin: 0 4px 3px 0;
                }
              }
            }
          }
        }
      }
      .part2 {
        margin-top: 50px;
        .left-content,
        .right-content {
          width: 49%;
        }
      }
      .part4 {
        .flex-content {
          background-color: #f5f7fd;
          padding: 20px;
          .GJCTable {
            width: 35%;
          }
        }
      }
      // .part3,
      // .part5,
      // .part6,
      // .part7 {
      //   margin-top: 50px;
      // }
    }
    .tip {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a6aabb;
      line-height: 20px;
      text-align: center;
      margin-top: 10px;
    }
    .page {
      height: 1573.33px;
      padding: 20px;
    }
    .page2 {
      .main-content-box {
        padding: 20px 40px 40px 40px;
      }
    }
    .page3 {
      .main-content-box {
        padding: 20px 40px;
      }
      .part5 {
        h3 {
          margin-top: 10px;
        }
      }
      .mb-20 {
        margin-bottom: 15px;
      }
    }
    .page4 {
      .main-content-box {
        padding: 20px 40px;
      }
      .part6 {
        h3 {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
